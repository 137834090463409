import React from "react";
import PropTypes from "prop-types";
// import theme from "../../theme/theme.yaml";

import Headline from "../Article/Headline";
import Bodytext2 from "../Article/Bodytext2";

const Page = (props) => {
  // const {
  //   page: {
  //     body,
  //     frontmatter: { title },
  //   },
  // } = props;

  // console.log("Page - props: ", props);

  return (
    <React.Fragment>
      <header>
        <Headline title={props.title} />
      </header>
      <Bodytext2>
        {props.children}
      </Bodytext2>
    </React.Fragment>
  );
};

export default Page;
